@media (max-width: 1023px) {
    .card-container {
      width: 100%;
      overflow: hidden;
    }
  
    .card-container > div {
      height: 100%;
      overflow-y: auto;
    }
  
    .mobile-main-card {
      height: 200px; /* MainCard의 높이 */
    }
  
    .mobile-blog-card {
      height: 200px; /* BlogCard의 높이 */
    }
  
    .mobile-broker-card {
      height: 200px; /* BrokerCard의 높이 */
    }
  
    .mobile-contact-card {
      height: 200px; /* ContactCard의 높이 */
    }
  }