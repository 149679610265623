/* 기존의 타일윈드 CSS, 글로벌 스타일 유지 */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* 폰트 적용을 위한 @font-face 추가 */
@font-face {
  font-family: 'Ibrand'; /* 폰트 이름 */
  src: url('./assets/fonts/Ibrand.woff2') format('woff2'), /* WOFF2 형식 */
       url('./assets/fonts/Ibrand.woff') format('woff'),   /* WOFF 형식 */
       url('./assets/fonts/Ibrand.otf') format('opentype'), /* OTF 형식 */
       url('./assets/fonts/Ibrand.ttf') format('truetype'); /* TTF 형식 */
  font-weight: normal;
  font-style: normal;
}

/* 글로벌 폰트 적용 */
body {
  margin: 0;
  font-family: 'Ibrand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; /* 기본 폰트를 Ibrand로 설정 */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 기타 스타일 */
.app-container {
  display: flex;
  min-height: 100vh;
  background-color: #f3f4f6;
}

.main-content {
  flex-grow: 1;
  margin-left: 164px;
  padding: 2rem;
}

.sidebar {
  width: 25%;
  background-color: white;
  padding: 2rem;
}

#root {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

body, html, #root {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}